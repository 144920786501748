var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const bootstrap = require('bootstrap');
var myModal;
export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init contact');
    myModal = new bootstrap.Modal(document.getElementById('modal-error'), {}); 

   //validate contact form    
   $('#js-btsend-contact').on('click', function() {
    $('#modal-error .modal-body').find('.errors').html('');
    myModal.hide(); 

    var error = false;
    var form = document.getElementById('fcontact');
    var formJS = $('#fcontact');
    //formJS.find('.alert .items-invalid').html("");
    var _errors= '';
    var name = document.getElementById('fieldname');
    //var fieldlastname = document.getElementById('fieldlastname');
    var fieldemail = document.getElementById('fieldemail');
    var fieldemail2 = document.getElementById('fieldemail2');
    //var selComment = document.getElementById('selComment');
    var txtAsk = document.getElementById('txtAsk');
    //var fieldlegal1 = document.getElementById('fieldlegal1');

    

  
    if (form.checkValidity() === false || $('#fieldemail').val() != $('#fieldemail2').val()) {
      error = true;
      if (!name.validity.valid) _errors += ("<li>" + $('#fieldname').closest('.form-group').data('error-message') + "</li>");
      //if (!fieldlastname.validity.valid) _errors += ("<li>" + $('#fieldlastname').closest('.form-group').data('error-message') + "</li>");
      if (!fieldemail.validity.valid) _errors += ("<li>" + $('#fieldemail').closest('.form-group').data('error-message') + "</li>");
      if ($('#fieldemail').val() != $('#fieldemail2').val()){
        _errors += ("<li>" + $('#fieldemail2').closest('.form-group').data('error-message') + "</li>");
      }
      //if (!fieldlegal1.validity.valid) _errors += ("<li>" + $('#fieldlegal1').closest('.form-group').data('error-message') + "</li>");
      //if (!selComment.validity.valid) _errors += ("<li>" + $('#selComment').closest('.form-group').data('error-message') + "</li>");
      if (!txtAsk.validity.valid) _errors += ("<li>" + $('#txtAsk').closest('.form-group').data('error-message') + "</li>");
    }

    formJS.addClass('was-validated');
    if (!error) {
      $('#js-btsend-contact').addClass('loader');

      //formJS.find('.alert').slideUp("fast");
      
      var datos = formJS.serialize();
      $.ajax({
          method: "POST",
          url: "ajax/ajax-contact.php",
          data: datos
        })
        .done(function(resultAjax) {
        formJS.removeClass('was-validated');
        resultContact(resultAjax);
        $('#js-btsend-contact').removeClass('loader');
      });
    } else {
      /*formJS.find('.alert').slideDown(function() {});
      plg.PLG_scrollTo(1, "#form-contact", 0, 100);*/
      $('#modal-error .modal-body').find('.errors').html(_errors); 
      $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
      myModal.show();
    }

  });     
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};



function resultContact(_result){

  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){
      
      var myModal = new bootstrap.Modal(document.getElementById('modal-contactook'), {
          keyboard: false
      });
      myModal.show();
    
  } else if(result['error']) {
      $("button").removeClass('loader');
      plg.PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
  } else {
      plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
  }
}