var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const bootstrap = require('bootstrap');
var myModal;
var myModalError;
var myModal2;

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init private');
    myModalError = new bootstrap.Modal(document.getElementById('modal-error'), {}); 


    $('.js-close-download').on('click', function(){
      myModal2.hide();
    });  



    $('.js-editName').on('click', function(){
      if ($('#nameupd').prop('readonly')){//si es readonly --> dejar modificar
        $('#nameupd').prop('readonly', false);
        $('#icoedit').attr('src', '/images/ico-guardar.svg');
        $('#icoedit2').attr('src', '/images/ico-noeditar.svg');
        $('#icoeditpass').attr('src', '/images/ico-noeditar.svg');
        $('.js-updpassword').addClass('no-cursor');

      }else{
        if ($('#nameupd').val() != ''){
          
          $.ajax({
            url: "ajax/ajax-update-name.php",
            data: "&nameupd="+$('#nameupd').val(),
            type: "POST",
            dataType: "json",
            success: function (response) {
              var obj, target, spinner_holder;
              $('#nameupd').prop('readonly', true);
              $('#icoedit').attr('src', '/images/ico-editar.svg');
              $('#icoedit2').attr('src', '/images/ico-editar.svg');
              $('#icoeditpass').attr('src', '/images/ico-editar.svg');
              $('.js-updpassword').removeClass('no-cursor');
            },
            error: function (xhr, status) {
              plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
            },
            complete: function (xhr, status) {
            }

          });
        }else{
          var _err = $('#nameupd').closest('.form-group').attr('data-error-message');
          $('#modal-error .modal-body').find('.errors').hide(); 
          $('#modal-error .modal-body').find('.txterrors').html(_err);
          myModalError.show();
        }  
        
      }  
      
    });

    $('.js-editLastName').on('click', function(){
      if ($('#lastnameupd').prop('readonly')){//si es readonly --> dejar modificar
        $('#lastnameupd').prop('readonly', false);
        $('#icoedit2').attr('src', '/images/ico-guardar.svg');
        $('#icoedit').attr('src', '/images/ico-noeditar.svg');
        $('#icoeditpass').attr('src', '/images/ico-noeditar.svg');
        $('.js-updpassword').addClass('no-cursor');

      }else{
        if ($('#lastnameupd').val() != ''){
          
          $.ajax({
            url: "ajax/ajax-update-lastname.php",
            data: "&lastnameupd="+$('#lastnameupd').val(),
            type: "POST",
            dataType: "json",
            success: function (response) {
              var obj, target, spinner_holder;
              $('#lastnameupd').prop('readonly', true);
              $('#icoedit2').attr('src', '/images/ico-editar.svg');
              $('#icoedit').attr('src', '/images/ico-editar.svg');
              $('#icoeditpass').attr('src', '/images/ico-editar.svg');
              $('.js-updpassword').removeClass('no-cursor');
            },
            error: function (xhr, status) {
              plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
            },
            complete: function (xhr, status) {
            }

          });
        }else{
          var _err = $('#lastnameupd').closest('.form-group').attr('data-error-message');
          $('#modal-error .modal-body').find('.errors').hide(); 
          $('#modal-error .modal-body').find('.txterrors').html(_err);
          myModalError.show();
        }  
        
      }  
      
    });

    $('#js-btcode-private').on('click', function() {
        var error = false;
        var form = document.getElementById('formCodePrivate');
        var formJS = $('#formCodePrivate');
        //formJS.find('.alert .items-invalid').html("");
        var _errors= '';
        var archivoInput = document.getElementById('ficfoto1');
        var archivo = archivoInput.files[0];
        //console.log('archivo::'+archivo);
        var hay_archivo = true;
        if (!archivo || archivo === undefined || archivo == 'undefined') {
          $('#div-ficfoto1').attr('data-error-message', 'Bitte lade deinen Kaufbeleg hoch.')
          _errors += ("<li>" + $('#div-ficfoto1').attr('data-error-message') + "</li>");
          error = true;
          hay_archivo = false;
        }
        
        if (hay_archivo){
          // Verificar el formato del archivo
          var formatoValido = /\.(jpeg|jpg|png|pdf)$/i.test(archivo.name);
          if (!formatoValido) {
            $('#div-ficfoto1').attr('data-error-message', 'Die Datei muss im JPEG-, JPG-, PNG- oder PDF-Format sein.')
            _errors += ("<li>" + $('#div-ficfoto1').attr('data-error-message') + "</li>");
            error = true;
          }
        
          // Verificar el tamaño del archivo
          var maxSize = 10 * 1024 * 1024; // 10MB
          if (archivo.size > maxSize) {
            $('#div-ficfoto1').attr('data-error-message', 'Die Datei darf nicht mehr als 10 MB groß sein.')
            _errors += ("<li>" + $('#div-ficfoto1').attr('data-error-message') + "</li>");
            error = true;
          }
        }  


        formJS.addClass('was-validated');
        if (!error) {
          $('#js-btcode-private').addClass('loader');
  
          var datos = formJS.serialize();
          var form = $('#formCodePrivate')[0];
          var formData = new FormData(form);
          formData.append('ficfoto1', archivo); 

          $.ajax({
              method: "POST",
              url: "ajax/ajax-check-code-private.php",
              data: formData,
              processData: false,
              contentType: false,
            })
            .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            $('#formCodePrivate')[0].reset();
            $('.nombre-archivo').html('');
            resultCodePrivate(resultAjax);
            $('#js-btcode-private').removeClass('loader');
          });
        } else {
          $('#modal-error .modal-body').find('.errors').html(_errors); 
          $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
          myModalError.show();
        }
  
      });

      $('.js-campaign').on('click', function(){
        var _idcampaign = $(this).attr('data-idcampaign');
        $.ajax({
          url: "ajax/ajax-select-campaign.php",
          data: "&promotion_id="+_idcampaign+"&credits="+$(this).attr('data-credits'),
          type: "POST",
          dataType: "json",
          success: function (response) {
              document.location.href="categories";            
          },
          error: function (xhr, status) {
            plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
          },
          complete: function (xhr, status) {
          }

        });

      });

      $('#js-btreset').on('click', function() {
        var error = false;
        var form = document.getElementById('formReset');
        var formJS = $('#formReset');
        //formJS.find('.alert .items-invalid').html("");
        var _errors= '';
        var password = document.getElementById('itPassword');
        var password2 = document.getElementById('itPassword2');
        if (form.checkValidity() === false || $('#itPassword').val() != $('#itPassword2').val()) {
            error = true;
            if (!password.validity.valid) _errors += ("<li>" + $('#itPassword').closest('.form-group').data('error-message') + "</li>");
            if ($('#itPassword').val() != $('#itPassword2').val()){
              _errors += ("<li>" + $('#itPassword2').closest('.form-group').data('error-message') + "</li>");
           }
        }
        formJS.addClass('was-validated');
        if (!error) {
            
          $('#js-btreset').addClass('loader');
  
          formJS.find('.alert').slideUp("fast");
          var datos = formJS.serialize();
          $.ajax({
              method: "POST",
              url: "ajax/ajax-reset-private.php",
              data: datos
            })
            .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            resultReset(resultAjax);
            $('#js-btreset').removeClass('loader');
          });
        } else {
          $('#modal-error .modal-body').find('.errors').html(_errors); 
          $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
          myModalError.show();
        }
  
      });
     
     $('.js-abrir-canjear').on('click', function(){
        $('#section-canjeo').removeClass('d-none');
        plg.PLG_scrollTo(1,'#section-canjeo',0,-100);//_duration,_targetDOM,_delay,_offset
     });

     $('.js-cancelar').on('click', function(){
      $('#section-canjeo').addClass('d-none');
     });

     $('.js-canjear').on('click', function(){
        $('.js-canjear').addClass('loader');
        $.ajax({
              method: "POST",
              url: "ajax/ajax-download-voucher.php",
            })
            .done(function(resultAjax) {
              resultDownloadVoucher(resultAjax);
              $('.js-canjear').removeClass('loader');
          });
     });


     $('.js-uploader').click(function(event) {
      /* Act on the event */
       var _nom_input = $(this).data('fichero');
       $('#'+_nom_input).trigger('click');
    });

    $('.fichero').on('change', function(){
       var idfichero = $(this).attr('id');

       var _fich = $("[data-fichero='"+idfichero+"']" ).attr('id');
       $('#'+_fich).addClass('ticket-ok');
       var _nom_tmp = $(this).val().split("\\");
       var _nom = _nom_tmp[_nom_tmp.length-1];
      // 
       var fileSize = this.files[0].size;
       var fileName = this.files[0].name;
       var _ext = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
       $(this).closest('.form-group').attr('data-tipo-error', '0');//no hay error
       if (fileSize > 10000000){//error tamaño
         $(this).closest('.form-group').attr('data-tipo-error', '2');
         $(this).closest('.form-group').addClass('has-error');
         $(this).closest('.form-group').removeClass('has-sucess');
       }

       _ext = _nom.slice((_nom.lastIndexOf(".") - 1 >>> 0) + 2);
       var validos = ['png', 'jpg', 'pdf', 'jpeg', 'PNG', 'JPG', 'JPEG', 'PDF', 'webp', 'WEBP'];
       if ($.inArray(_ext, validos) < 0){//error extensión
         $(this).closest('.form-group').attr('data-tipo-error', '3');
         $(this).closest('.form-group').addClass('has-error');
         $(this).closest('.form-group').removeClass('has-sucess');
       } 



       var _target = $(this).attr('data-targetFichero');
       //alert(_target);
       $('#'+_target).val(_nom);
       $('.nombre-archivo').html(_nom);
    });



  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_sectioncampaigns(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK' ){
      var objName='#section_campaigns .loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      jQuery.each( _resultcb['data'], function( i, val ) {
        var tmpobj=_elem.clone(true).appendTo(_elem.parent());      
        
        tmpobj.find(".title").html(val.title);
        tmpobj.find(".credits").html(val.credits);
        tmpobj.find(".expire_min").html(val.expire_min);
        tmpobj.attr('data-idcampaign', val.id);
        tmpobj.attr('data-credits', val.credits);
        jQuery.each(val.categories, function (j, val2) {
          console.log(val2);
          // tmpobj.find(".networks").append('<div class="category"><img src="'+val2.image_network+'"><div class="d-none hover">'+val2.description+'</div></div>');
          tmpobj.find(".networks").append('<div class="category"><img src="'+val2.image_network+'"><a class="ico-desplegable" href="javascript:;"><img src="/images/ico-desplegable.svg"><div class="d-none hover">'+val2.description+'</div></a></div>');

        });
        tmpobj.removeClass("loadajax-item");
        tmpobj.css('opacity',0);
        tmpobj.find('.card-info').css('opacity',1);
        tmpobj.delay(i*50).animate({opacity: 1},400);
        
      });
      
      $(objName).remove();
    }  
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};



function resultCodePrivate(_result){
    var result = JSON.parse(_result);
    if (result['result'] == 'OK' ){
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Code entered", {
          "Client Name": result['client_name'],
          "Promotion ID": result['id_promocion']
        });
      }
      $('#txtcreditos').html(result['totalcredits']);
      
      plg.PLG_showModalAlerta(result['text1'], result['text2']);
    }else{
        $('#js-btcode-private').removeClass("loader");

        $('#modal-error .modal-body').find('.errors').hide(); 
        $('#modal-error .modal-body').find('.txterrors').html(result['error']);
//        $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
//        $('#modal-error .modal-button').show();
        myModalError.show();
    }
}

function resultReset(_result){
  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){
      document.location.href = 'change-password-end';
  }else{
      $('#js-btreset').removeClass("loader");

      $('#modal-error .modal-body').find('.errors').hide(); 
      $('#modal-error .modal-body').find('.txterrors').html(result['error']);
      $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
      $('#modal-error .modal-button').show();
      myModalError.show();
  }
}


function resultDownloadVoucher(_result){
  
  var result = JSON.parse(_result);
  
  if (result['result'] == 'OK'){
    //$('#modalDownloadOk').show(); 
    myModal2 = new bootstrap.Modal(document.getElementById('modalDownloadOk'), {
      keyboard: false
    });
    myModal2.show(); 
    $('.js-user_credits').html('0');
    $('.js-einlosen').addClass('d-none');
    $('.js-convertir').removeClass('d-none');
    $('#section-canjeo').addClass('d-none');
    //MIXPANEL
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Voucher downloaded");
    }  
    
  }else{    
    if (result['error']['msg_error'] == '' || result['error']['msg_error'] == null){
      plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
    }
    else{
      plg.PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
    }
  } 
  
 
  
}